import { useRef } from 'react';
import { createComponent, IntrinsicProps } from 'react-commons';
import { AdUnitType, usePlaywireAdUnit, usePlaywireIdRef } from '@/components/playwire/Playwire';

import style from './index.module.scss';

interface PlaywireUnitProps extends IntrinsicProps {
  unit: AdUnitType
}

export default createComponent<PlaywireUnitProps>('PlaywireUnit', { style }, function PlaywireUnit ({}, props) {
  const ref = useRef<HTMLDivElement>(null);
  const idRef = usePlaywireIdRef();

  usePlaywireAdUnit(ref, { type: props.unit, selectorId: idRef.current });

  return (
    <div ref={ref} className='PlaywireUnit'>
      <div id={idRef.current} />
    </div>
  );
});
