import 'react';

import { PlaywireCornerVideoOptions, usePlaywireCornerVideo } from '@/components/playwire/Playwire';

interface PlaywireCornerVideoProps {
  options?: PlaywireCornerVideoOptions
}

export default function PlaywireCornerVideo (props: PlaywireCornerVideoProps) {
  usePlaywireCornerVideo(props.options);

  return (<></>);
};
